/* You can add global styles to this file, and also import other style files */
body {
    font-family: "Manrope", sans-serif;
}

.text-aqua {
    color: #04B2D9;
}

.button-primary {
    color: white;
    background-color: #04B2D9;
}

.text-aqua:hover {
    color: #0092b3;
}

.button-primary:hover {
    color: white;
    background-color: #0092b3;
}


#btnProduct.mat-mdc-select .mat-mdc-select-trigger {
    color: white !important;
    background-color: #17a2b8;
}
#btnProduct.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-placeholder {
    color: white !important;
}
#btnProduct.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow svg {
    color: white !important;
}


.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    cursor: pointer;
    background-color: white !important;
}

.mat-mdc-form-field:hover {
    cursor: pointer;
    background-color: white !important;
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding: 0 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
